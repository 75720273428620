import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap"
import SearchComponent from "./search"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./header.scss"

import { connect } from "react-redux"

const mapStateToProps = ({
  url,
  id,
  subcontinent,
  level_0,
  level_1,
  level_2,
  adm0,
  adm1,
  adm2,
  admin_level,
  isLoggedin,
}) => {
  return {
    url,
    id,
    subcontinent,
    adm0,
    adm1,
    adm2,
    level_0,
    level_1,
    level_2,
    admin_level,
    isLoggedin,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    select_continent: continent =>
      dispatch({ type: `SELECT_CONTINENT`, id: continent }),
  }
}

const Header = ({
  url,
  id,
  siteTitle,
  select_continent,
  subcontinent,
  adm0,
  adm1,
  adm2,
  level_0,
  level_1,
  level_2,
  admin_level,
  isLoggedin,

}) => {
  var breadcrumbData = []

  if (subcontinent) {
    breadcrumbData.push({ level: -1, name: subcontinent })
  }

  if (level_0) {
    breadcrumbData.push({ level: 0, name: level_0 })
  }

  if (level_1) {
    breadcrumbData.push({ level: 1, name: level_1 })
  }
  if (level_2) {
    breadcrumbData.push({ level: 2, name: level_2, url: url })
  }

  const data = useStaticQuery(graphql`
    query {
      branding: file(relativePath: { eq: "branding.png" }) {
        childImageSharp {
          fixed(height: 50){
            ...GatsbyImageSharpFixed
          }
            
        }
      }
    }
  `)
console.log(data)
  return (
    <Navbar className="navigation-bar" style={{minHeight: "70px"}}>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <div className="brand">
            {/* <img src="./assets/branding.svg" alt="covid dashboard logo" /> */}
            <Img fixed={data.branding.childImageSharp.fixed} />

            <div className="title">
              <h1 id="brandTitle" className="brandText">
                Crop Production Monitor
              </h1>
              <h2 id="brandSubtitle" className="brandText">
                {siteTitle}
              </h2>
            </div>
            {/* <img src="./assets/branding.svg" alt="Big Data in Agriculture Branding" /> */}

          </div>
        </Navbar.Brand>
        <Nav className="mr-auto">
          <NavDropdown title="About" id="nav-dropdown">
            <NavDropdown.Item
              eventKey="4.1"
              className="dropdown-link"
              as={Link}
              to="/about#background"
            >
              Background
            </NavDropdown.Item>

            <NavDropdown.Item
              eventKey="4.2"
              className="dropdown-link"
              as={Link}
              to="/about#methodology"
            >
              Methodology
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="4.3"
              className="dropdown-link"
              as={Link}
              to="/about#glossary"
            >
              Glossary
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="4.4"
              className="dropdown-link"
              as={Link}
              to="/about#about-us"
            >
              About Us
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="4.5"
              className="dropdown-link"
              as={Link}
              to="/about#acknowledgement"
            >
              Acknowledgement
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="4.6"
              className="dropdown-link"
              as={Link}
              to="/about#citation"
            >
              Citation
            </NavDropdown.Item>
            {isLoggedin ? (
              <NavDropdown.Item
                eventKey="4.7"
                className="dropdown-link"
                as={Link}
                to="/about#data-download"
              >
                Data Download
              </NavDropdown.Item>
            ) : (
                ""
              )}
          </NavDropdown>
          {breadcrumbData.map(level => {
            var url = ""
            switch (level.level) {
              case -1:
                url = subcontinent.toLowerCase().replace(/ /g, "")
                break
              case 0:
                url = level_0.toLowerCase().replace(/ /g, "")
                break
              case 1:
                url =
                  level_1.toLowerCase().replace(/ /g, "") +
                  "-" +
                  adm0.toLowerCase().replace(/ /g, "")
                break
              default:
                url =
                  level_2.toLowerCase().replace(/ /g, "") +
                  "-" +
                  adm1.toLowerCase().replace(/ /g, "")
                break
            }

            if (level.level < 2) {
              return (
                <Link className="navbarLink" to={"/" + url} key={url}>
                  {level.name}
                </Link>
              )
            } else {
              return null
            }
          })}
        </Nav>
        <Nav>
          <SearchComponent />
        </Nav>
      </Container>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}


export default connect(mapStateToProps, mapDispatchToProps)(Header)
