import React, { Component} from "react"

import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import SearchResults from "./search-results"
import "./search.scss"

class SearchComponent extends Component {
  constructor(props) {
    super(props)
    this.searchRef = React.createRef()
  }
  state = {
    searchQuery: "",
  }
  handleSearchEntry = event => {
    this.setState({
      searchQuery: event.target.value,
    })
  }

  focusEvent = event => {
    this.setState({
      searchQuery: event.target.value,
    })
  }
  render() {
    const { searchQuery } = this.state

    const renderPopover = (
      <Popover id="search-popover">
        <Popover.Content id="search-results-popover">
          <SearchResults queryText={searchQuery} />
        </Popover.Content>
      </Popover>
    )
    return (
      <div>
        <OverlayTrigger
          placement="bottom"
          trigger="focus"
          delay={{ show: 250, hide: 400 }}
          overlay={renderPopover}
        >
          <input
             aria-label="search region"
            value={searchQuery}
            ref={this.searchRef}
            type="text"
            placeholder="Search"
            className="mr-sm-2"
            id="search-bar"
            autoComplete="off"
            onFocus={this.focusEvent}
            onChange={this.handleSearchEntry}
          />
        </OverlayTrigger>
      </div>
    )
  }
}

export default SearchComponent
