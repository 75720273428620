import React, { Component } from "react"
import * as JsSearch from "js-search"
import "./search-results.scss"
import DataStore from "../data/source/locations.json"
import { Link } from "gatsby"

class SearchResults extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchResults: "",
    }
  }

  componentDidMount() {
    this.searchData(this.props.queryText)
  }

  componentDidUpdate(prevProps) {
    if (this.props.queryText !== prevProps.queryText) {
      this.searchData(this.props.queryText)
    }
  }
  searchData = textSearch => {
    var search = new JsSearch.Search("id")
    search.addIndex("ADM0_EN")
    search.addIndex("ADM1_EN")
    search.addIndex("ADM2_EN")
    search.addDocuments(DataStore)
    this.setState({
      searchResults: search.search(textSearch),
    })
  }

  render() {
    const { searchResults } = this.state
    const queryResults = searchResults === "" ? [] : searchResults
    return (
      <div id="search-results">
        {queryResults
          .sort((a, b) => {
            var nameA = a.id.toUpperCase() // ignore upper and lowercase
            var nameB = b.id.toUpperCase() // ignore upper and lowercase
            if (nameA < nameB) {
              return -1
            } else {
              return 1
            }
          })
          .map(result => {
            if (result.ADM2_EN) {
              return (
                <Link to={"/" + result.url} key={result.url}>
                  <div class="search-result">
                    <h3 class="adm0-result">{result.ADM0_EN}</h3>
                    <p class="result">
                      {result.ADM1_EN} > {result.name}
                    </p>
                  </div>
                </Link>
              )
            } else if (result.ADM1_EN) {
              return (
                <Link to={"/" + result.url} key={result.url}>
                  <div class="search-result">
                    <h3 class="adm0-result">{result.ADM0_EN}</h3>
                    <p class="result">{result.name}</p>
                  </div>
                </Link>
              )
            } else {
              return (
                <Link to={"/" + result.url} key={result.url}>
                  <div class="search-result">
                    <h3 class="adm0-result">{result.name}</h3>
                  </div>
                </Link>
              )
            }
          })}
      </div>
    )
  }
}

export default SearchResults
