import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "./layout.scss"
import BackgroundImage from "gatsby-background-image"

import Header from "./header"
import "./layout.css"

import { connect } from "react-redux"

const mapStateToProps = ({ isLoggedin }) => {
  return {
    isLoggedin,
  }
}

const Layout = ({ children, isLoggedin }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      desktop: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  console.log(data)

  return (
    <div>
      <BackgroundImage
        fluid={data.desktop.childImageSharp.fluid}
        className="bg-image"
        style={{
          width: "100vw",
          height: "100vh",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="gradient"></div>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `1rem auto`,
            maxWidth: 1200,
            padding: `0 1.0875rem`,
            zIndex: `10000`,
          }}
        >
          <main
            style={{
              paddingTop: `1rem`,
            }}
            className="mainContent"
          >
            {children}
            
          </main>
          <footer></footer>
        </div>
      </BackgroundImage>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(mapStateToProps)(Layout)
